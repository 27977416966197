import React, { ComponentType, ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import i18n from '@/application/i18n';
import { DayJsLocalization } from '@/application/layouts/DayJsLocalization';

function Layout({ children }: { children: ReactNode }) {
  return (
    <RecoilRoot>
      <DayJsLocalization>
        <I18nextProvider i18n={i18n}>
          <Router>
            {children}
          </Router>
        </I18nextProvider>
      </DayJsLocalization>
    </RecoilRoot>
  );
}

/**
 * Wraps a component within a Layout
 * @param Component
 */
// eslint-disable-next-line func-names
export const withLayout = <P extends object>(Component: ComponentType<P>) => function (props) {
  return <Layout><Component {...props as P} /></Layout>;
};

export default Layout;
