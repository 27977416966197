import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';

import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { userLocale } from '@/application/atoms/exports';

export function DayJsLocalization({ children }: { children: JSX.Element }) {
  const locale = useRecoilValue(userLocale);
  useEffect(() => {
    dayjs.extend(LocalizedFormat);
    dayjs.locale(locale);
  }, []);

  return children;
}
